<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="case-list">
      <div class="zuixin-gingne"><span style="color:#999999;">帮助中心></span>帮助详情</div>
        <div class="case-details-totle">
            <div class="totlelost">{{center.title}}</div>
            <div class="totlelost">{{center.send_time}}</div>
            <div class="lisrcente" v-html="center.details.replace(/\n/g,'<br/>')"  @click="htmlBoxClick($event)"></div>
            <el-image
                style="display: none;"
                ref="preview"
                class="hideImgDiv"
                :src="imgPreviewSrc[0]"
                :preview-src-list="imgPreviewSrc"
            ></el-image>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name:"guizedetail",
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
        center:{},
        imgPreviewSrc:""
      }
    },
    mounted () {
        this.id= this.$route.query.id
        window.scrollTo(0, 0);
     console.log(this.id,'@@@@')
     this.getgzzxinlist()
    },
    methods: {
      // 图片点击放大
      htmlBoxClick(e){ 
        // console.log(e,'gggggg')
        if (e.target.nodeName === 'IMG'|| e.target.nodeName == 'img') { //判断是否图片标签
          const img = e.target.currentSrc //获取点击图片地址
          this.imgPreviewSrc=[img];
          this.$refs.preview.clickHandler();
          return false;
        }
      },
      getgzzxinlist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getHelpInfo?id="+ that.id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.center = res.data.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    /* background-color: #F2F5FB ; */
    background-color: #ffffff;
     padding-top:87px;
  }
 
  .case-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
    
  }
  .case-details-totle{
    /* text-align: center; */
    min-height: 1090px;
  }
  .case-details-totle div:nth-child(1){
    font-weight: 550;
    font-size: 24px;
    color: #333333;
    padding: 20px 0;
  }
  .case-details-totle div:nth-child(2){
    font-size: 14px;
    color: #999999;
    /* padding: 10px 0; */
  }
  .totlelost{
    margin-top: 5px;
    text-align: center;
  }
  .lisrcente{
    width: 90%;
    /* text-align: center; */
    margin: 15px auto;
    white-space: pre-wrap;
  }
  .lisrcente>>> img {
        max-width: 100% ;
  }
  .zuixin-gingne{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    padding: 0 0 22px 0;
  }
</style>


